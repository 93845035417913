import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";
import Modal from "../../modal";

import './product.css';

const ALL_TYPES = {
  bracelet:'bracelet',
  earrings:'earrings',
  ring:'ring',
  necklace:'necklace',
  pendant:'pendant',
  watch:'watch'
}

const ALL_CATALOGS = {
  // bering:"Bering",
  // chicpistachio:"Chic Pistachio",
  // citizen:"Citizen",
  // classicgrown:"Classic Grown",
  // jorgerevilla:"Jorge Revilla",
  // michou:"Michou",
  // orfega:"Orfega",
  // silverelegance:"Silver Elegance",
  // steelrevolt:"Steel Revolt",
  // sterlingreputation:"Sterling Reputation",
  // twinklesbysevan:"Twinkles by Sevan",
  // tisentomilano:"Ti Sento Milano"
}

const ALL_CATEGORIES = {
  // bridal:"bridal",
  // gents:"gents",
  // custom:"custom",
  // lab:"lab",
  // cz:"cz",
  // diamond:"diamond",
  // ruby:"ruby",
  // emerald:"emerald",
  // sapphire:"sapphire",
  // gemstone:"gemstone",
  // sterling:"sterling",
  // gold:"gold",
  // '10k':"10k",
  // '14k':"14k",
  // '18k':"18k",
  // platinum:"platinum"
}
class Product extends Component {

  constructor(props){
    super(props)

    let qvals={}
    let selectedTab

        (window.location.href.split('?')[1] || '').split('&').map((vv) => {
        let vx = vv.split('=')
        qvals[vx[0]] = vx[1]
    })
    if (qvals.selectedTab) {
        selectedTab = qvals.selectedTab
    }
  	this.state={
      selectedTab:selectedTab||'categories',
  		isDirty:false,
  		productId:window.location.href.split('/product/')[1].split('/').slice(0)[0],
  		product:{},
  		sku:{},
      categories:[],
      photos:[],
      queue:[],
      sales:[],
      sizeOption:'',
      colorOption:'',
      variationSizeOption:'',
      variationColorOption:'',
      variationStockOption:'0',
      variationImageOption:'',
      variationStockUpdateIndex:null,
      variationStockUpdateOption:null,
      showGPT:false
  	}

  	this.fetch()
  }

  componentDidMount() {
    let self=this
    // console.log({lproduct:'mounted'})
    window.addEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  componentWillUnmount() {
    let self=this
    // console.log({lproduct:'unmounting'})
    window.removeEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  handleNavigation(e){
    // console.log({lproduct:'handleNavigation',e,isDirty:this.state.isDirty})
    // return "Are you sure you want to leave?"
    if(this.state.isDirty){
      // e.returnValue =  "Are you sure you want to leave?"
      var confirmationMessage = "Are you sure you want to leave?";

      (e || window.event).returnValue = confirmationMessage;     // Gecko + IE
      return confirmationMessage;
    }
    
  }

  fetch(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/products/fetch/"+self.state.productId)
      .then(res => {
        if (res.data) {
          self.setState({
            product:res.data.product,
            sku:{
              ...res.data.sku,
              price:(res.data.sku.price||0)/100
            }
          },()=>{

            self.fetchCategories(cb)
          })
        }
        else{

          self.setState({product:{}},()=>{
            self.fetchCategories(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({product:res});
        // }
      })
      .catch(error => {
        console.log(error);
        self.fetchCategories(cb)
      });

  }
  fetchCategories(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/products/categories")
      .then(res => {
        if (res.data) {
          self.setState({categories:res.data},()=>{

            self.fetchCatalogs(cb)
          })
        }
        else{

          self.setState({categories:[]},()=>{
            self.fetchCatalogs(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({categories:res});
        // }
      })
      .catch(error => {
        console.log(error);
        self.fetchCatalogs(cb)
      });
  }
  fetchCatalogs(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/products/catalogs")
      .then(res => {
        if (res.data) {
          self.setState({catalogs:res.data},()=>{

            self.fetchQueue(cb)
          })
        }
        else{

          self.setState({catalogs:[]},()=>{
            self.fetchQueue(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({catalogs:res});
        // }
      })
      .catch(error => {
        console.log(error);
        self.fetchQueue(cb)
      });
  }

  fetchQueue(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/products/queue/"+self.state.productId)
      .then(res => {
        if (res.data && res.data.queue) {
          self.setState({queue:res.data.queue},()=>{

            self.fetchSales(cb)
          })
        }
        else{

          self.setState({queue:{}},()=>{
            self.fetchSales(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({queue:res});
        // }
      })
      .catch(error => {
        console.log(error);
        self.fetchSales(cb)
      });

  }
  fetchSales(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/products/sales/"+self.state.productId)
      .then(res => {
        if (res.data && res.data.sales) {
          self.setState({sales:res.data.sales},()=>{

            self.fetchPhotos(cb)
          })
        }
        else{

          self.setState({sales:{}},()=>{
            self.fetchPhotos(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({sales:res});
        // }
      })
      .catch(error => {
        console.log(error);
        self.fetchPhotos(cb)
      });

  }

  updateField(field,value,callback){
    let product = this.state.product
    product[field]=value
    this.setState({product,isDirty:true},callback)
  }
  updateMetadataField(field,value,callback){
    let product = this.state.product
    let metadata = product.metadata || {}
    metadata[field]=value
    product.metadata=metadata
    this.setState({product,isDirty:true},callback)
  }
  updateSkuField(field,value,callback){
    let sku = this.state.sku
    sku[field]=value
    this.setState({sku,isDirty:true},callback)
  }
  updateFields(fields,values,callback){
    let product = this.state.product
    fields.forEach((f,fx)=>{
      product[f]=values[fx]  
    })
    
    this.setState({product,isDirty:true},callback)
  }

  update(callback){
        let self = this
        let product = this.state.product
        let {name,description,metadata,active,images} = self.state.product
        let {price,inventory} = self.state.sku

        axios.put('/api/products/' + product.id, {
          name,description,price,inventory:inventory.quantity,metadata,active,images
        }).then((data) => {
            console.log(`Product updated successfully`);


            this.setState({product,isDirty:false},()=>{
              self.fetch(()=>{
                if(callback){
                  callback()
                }
                // self.props.onUpdate()
              })  
            })
            
            // swal({
            //   title:"Success!",
            //   text:'Product updated successfully',
            //   type:"success",
            //   timer:1000
            // }).then(value => {
                // window.location = '/product?t=' + new Date().getTime()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });
  }

  delete(){
    let self=this
    console.log(this.state.sale)

    let additionalWarning=''

    swal(additionalWarning+ "Are you sure you want to delete this product?", {
      icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
      if(this.state.photos && this.state.photos.length){
        swal("Error!", "You must first delete all photos associated with this product.", "error");
        return
      }

      switch (value) {
        case "sure":
          
            console.log('Deleted')
        axios.delete('/api/products/'+self.state.product._id, {})
        .then( (data) => {
            console.log(`Product deleted successfully`);
                window.location='/admin/products'
            // console.log(data)
              // self.props.addPhotoToModel(data.data.filename)
          // self.setState({
          //  selectedNote:data.data,
          // },self.filterResults)
          }).catch((e) => {
            console.log('Product delete failed, Error ',e)
          // self.setState({errorMessage:e})
        });
          break;
        case "nope":
            console.log('Didnt Delete (cancel)')
          break;
        default:
            console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });

  }

  fetchPhotos(callback){
    let self=this
    let cb = callback || function(){}

    axios
      .get("/api/photos/products/"+self.state.productId)
      .then(res => {
        if (res.data) {
          self.setState({photos:res.data},cb)
        }
        else{

          self.setState({photos:[]},cb)  
        }
        // else if (res.data.result === "error") {
          console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
        cb()
      });

  }

  updatePhoto(field,value){
    let photos = this.state.photos
    photos[this.state.currentImage][field]=value
    this.setState({photos})
    console.log({updatePhoto:{field,value}})
  }

  // savePhoto(){
  //     let photo = this.state.photos[this.state.currentImage]


  //   axios.put('/api/photos/'+photo._id, {photo})
  //   .then( (data) => {
  //       console.log(`Item updated successfully`);
  //       // self.fetchPhotos()
  //       console.log(data)
  //         // self.props.addPhotoToModel(data.data.filename)
  //     // self.setState({
  //     //  selectedNote:data.data,
  //     // },self.filterResults)
  //     }).catch((e) => {
  //       console.log('Photo update failed, Error ',e)
  //     // self.setState({errorMessage:e})
  //   });
  // }
  newCatalog(catalog){
    
    let self = this
    axios.post('/api/products/catalog', {catalog}, {timeout:1000*60*20})
    .then((cat)=>{
      console.log({cat})
      self.fetchCatalogs()
    }).catch((err) => {
      console.log({err})
    // self.setState({errorMessage:e})
    });	

  }
  newCategory(category){
    
    let self = this
    axios.post('/api/products/category', {category}, {timeout:1000*60*20})
    .then((cat)=>{
      console.log({cat})
      self.fetchCategories()
    }).catch((err) => {
      console.log({err})
    // self.setState({errorMessage:e})
    });	

  }
  deleteCatalog(catalogId,callback){
    
    let self = this
    let cb = callback || function(){}

    swal("Are you sure you want to delete this Catalog?\n(other products may still reference this catalog)", {
      icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      switch (value) {
        case "sure":
          
          console.log('Deleted')
        
          axios.delete(`/api/products/catalog/delete/${catalogId}`, {timeout:1000*60*20})
          .then((cat)=>{
            console.log({cat})
            self.fetchCatalogs()
            cb()
          }).catch((err) => {
            console.log({err})
          // self.setState({errorMessage:e})
          });	
          break;
        case "nope":
            console.log('Didnt Delete (cancel)')
          break;
        default:
            console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });


  }
  deleteCategory(categoryId,callback){
    
    let self = this
    let cb = callback || function(){}

    swal("Are you sure you want to delete this Category?\n(other products may still reference this category)", {
      icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      switch (value) {
        case "sure":
          
          console.log('Deleted')
        
                
          axios.delete(`/api/products/category/delete/${categoryId}`, {timeout:1000*60*20})
          .then((cat)=>{
            console.log({cat})
            self.fetchCategories()
            cb()
          }).catch((err) => {
            console.log({err})
          // self.setState({errorMessage:e})
          });	
          break;
        case "nope":
            console.log('Didnt Delete (cancel)')
          break;
        default:
            console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });



  }
  newPhoto(file){
    let self = this

    console.log(file)

    // let extension = file.name.split('.').slice(-1)[0]
    let extension = file.type .split('/').slice(-1)[0]
    const blob = new Blob([file], { type: file.type });// WORKS much better (if you know what MIME type you want.
    const formData = new FormData();
    formData.append('file', blob, this.state.product.id + '_'+new Date().getTime() + '.' + extension);
  
    console.log({formData})

    // self.setState({editField:'imageWait'},()=>{
      axios.post('/api/products/images', formData, {timeout:1000*60*20})
      .then((upload)=>{
        if(upload && upload.data && upload.data.url){
          let images = self.state.product.images || []
          images.push(upload.data.url)
          self.updateField('images',images,()=>{
            self.update(()=>{
              console.log({updating1:true})
              // window.location.reload()

              // window.$("#slider ul").bxSlider({
              // 	controls: false,
              // 	auto: false,
              // 	mode: 'fade',
              // 	preventDefaultSwipeX: false
              // });
              // window.location = '/editProduct/' + this.state.productId + '?t='+new Date().getTime()
            })
          })
        }
        console.log({upload})
      }).catch((err) => {
        console.log({err})
      // self.setState({errorMessage:e})
      });	
    // })
  }
  // newPhoto_old(fileObj){
  //   let self=this
  //   console.log(this.state)

  //   var formData = new FormData();
  //   formData.append("file", fileObj);
  //   formData.append("type", 'products');
  //   formData.append("refId", this.state.product._id);

  //   axios.post('/api/photos', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //   })
  //   .then( (data) => {
  //       console.log(`Item created successfully`);
  //       self.fetchPhotos()
  //       console.log(data)
  //         // self.props.addPhotoToModel(data.data.filename)
  //     // self.setState({
  //     //  selectedNote:data.data,
  //     // },self.filterResults)
  //     }).catch((e) => {
  //       console.log('Creation failed, Error ',e)
  //     // self.setState({errorMessage:e})
  //   });

  // }

  deletePhoto(url){
    let self = this

      swal("Are you sure you want to delete this file?", {
          buttons: {
              nope: {
                  text: "Cancel",
                  value: "nope",
                  className: "btn-primary"
              },
              sure: {
                  text: "Delete",
                  value: "sure",
                  className: "btn-danger"
              }
          }
      }).then(value => {

          switch (value) {
              case "sure":


                self.setState({editField:'imageWait'},()=>{

            let fileName = url.split('/').slice(-1)[0]

          axios.delete('/api/products/images', {data:{fileName}}).then((result)=>{
            console.log({result})
            if(result && result.data && result.data.ok){
              let images = (self.state.product.images || []).filter((i)=>{return i!==url})
              // let images = self.state.images.filter((i)=>{return i!==url})
              self.updateField('images',images,()=>{
                self.update(()=>{
                  console.log({updating2:true})
                  // window.location='/admin/product/'+self.state.product.id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
                  // window.location.reload()

                  // window.$("#slider ul").bxSlider({
                  // 	controls: false,
                  // 	auto: false,
                  // 	mode: 'fade',
                  // 	preventDefaultSwipeX: false
                  // });
                  // window.location = '/editProduct/' + this.state.productId + '?t='+new Date().getTime()
                })
              })
            }
          }).catch((err) => {
            console.log({err})
          // self.setState({errorMessage:e})
          });
                })
                  break;
              case "nope":
                  // swal("Ok", "success");
                  self.setState({editField:'null'})
                  break;
              default:
                  // swal("Got away safely!");
          }
      });

  }
  deletePhoto_old(){
    let self=this
      let photo = this.state.photos[this.state.currentImage]


    axios.delete('/api/photos/'+photo._id, {})
    .then( (data) => {
        console.log(`Photo deleted successfully`);
          window.location='/admin/product/'+self.state.product._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
        // console.log(data)
          // self.props.addPhotoToModel(data.data.filename)
      // self.setState({
      //  selectedNote:data.data,
      // },self.filterResults)
      }).catch((e) => {
        console.log('Photo delete failed, Error ',e)
      // self.setState({errorMessage:e})
    });
    // let photo = this.state.sale.photos[this.state.currentImage]
    // this.props.deleteModelPhoto(photo)
    // console.log({deletePhoto:photo})
    // console.log({deletePhoto:this.state.currentImage})
  }

  onCurrentImageChange(index) {
    console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }




  render() {
		let self=this
		let name=''



		let updateButton=null
		if(self.state.isDirty){
			updateButton=(

	            <li key='update_button' id='sadas'>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>update</button>
	            </li>
			)
		}

    
	let gptModal = null


	  if(self.state.showGPT){

		  gptModal = (

						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showGPT:false,gptInput:'',gptResult:'',isProcessingGPT:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'70vw',height:'70vmin',zIndex:1000,padding:'10px'}}>
                  <h3>GPT Description Assistant</h3>
                    <textarea 
                        className="form-control" 
                        rows="4" 
                        onChange={(e)=>{self.setState({gptInput:e.target.value})}}
                        value={this.state.gptInput}
                        style={{fontSize:'.9em'}}
                        placeholder={`Enter GPT prompt here...
ex:
Describe a 1 ct tdw anniversary band with 15 stones set in 14k white gold in 1 paragraph`}/>
                      
                    <hr/>
                    <textarea 
                        className="form-control" 
                        disabled
                        rows="14" 
                        value={this.state.gptResult}
                        placeholder="..."/>

                    <div style={{width:'100%',marginTop:'10px'}}>
                      <div style={{display:'inline-block', width:'33%', textAlign:'center'}}>
                        <button type="button" className="btn btn-block btn-danger" style={{width:'120px',display:'inline-block'}} onClick={(e)=>{
                            self.setState({showGPT:false,gptInput:'',gptResult:'',isProcessingGPT:false})
                        }}>Cancel</button>
                      </div>
                      <div style={{display:'inline-block', width:'33%', textAlign:'center'}}>
                        
                        <button 
                          type="button" 
                          className="btn btn-block btn-primary" 
                          style={{width:'120px',display:'inline-block'}} 
                          disabled={self.state.isProcessingGPT}
                          onClick={(e)=>{
                            self.setState({gptResult:'',isProcessingGPT:true},()=>{

                              axios
                              .post("/api/gpt",{text:self.state.gptInput})
                              .then(res => {
                                let gptRes = res.data.result
                                self.setState({gptResult:gptRes.message.content,isProcessingGPT:false})
                                // console.log({sales:res});
                              })
                              .catch(error => {
                                console.log(error);
                              });

                            })
                          }}>{self.state.isProcessingGPT?'Processing...':'Generate'}</button>
                      </div>
                      <div style={{display:'inline-block', width:'33%', textAlign:'center'}}>
                        
                        <button type="button" disabled={!this.state.gptResult} className="btn btn-block btn-success" style={{width:'120px',display:'inline-block'}} onClick={(e)=>{

                          const descText = `${self.state.gptResult}`
                          self.updateField('description',descText.trim())
                          self.setState({showGPT:false,gptInput:'',gptResult:'',isProcessingGPT:false})
                        }}>Accept</button>
                      </div>
                    </div>
                </div>
              </div>
            </Modal>
      )
    }

    let activeButton=null

    let prodCategories = []
    let prodSizeOptions = []
    let prodColorOptions = []
    let prodVariationOptions = []

    if(self.state.product.id){
      name = self.state.product.name
      prodCategories = ((self.state.product.metadata || {})['EJR-CAT'] || '').toLowerCase().split(',').filter((p)=> {return p && p.trim().length})
      prodSizeOptions = ((self.state.product.metadata || {})['EJR-SIZE-OPTS'] || '').toLowerCase().split(',').filter((p)=> {return p && p.trim().length})
      prodColorOptions = ((self.state.product.metadata || {})['EJR-COLOR-OPTS'] || '').toLowerCase().split(',').filter((p)=> {return p && p.trim().length})
      prodVariationOptions = ((self.state.product.metadata || {})['EJR-VAR'] || '').toLowerCase().split(',').filter((p)=> {return p && p.trim().length})
      



      if(self.state.product.active){
        activeButton=(

              <li key='deactivate_button'>          
                <button type="button" className="btn btn-block btn-danger" onClick={(e)=>{
                  self.updateField('active',false,()=>{
                    self.update()
                  })
                }}>Make Inactive</button>
              </li>
        )
      }
      else{
        activeButton=(

              <li key='activate_button'>          
                <button type="button" className="btn btn-block btn-primary" onClick={(e)=>{
                  self.updateField('active',true,()=>{
                    self.update()
                  })
                }}>Make Active</button>
              </li>
        )

      }
    }

    let pImages = (this.state.product || {}).images || []
    
// console.log({pImages})
        let galleryPhotos=pImages.map((p,pid)=>{
      return {
            src: p,
            thumbnail: p,
            width:400,
            height:400,
            // src: axios.defaults.baseURL + "/api/photo/"+p.type+'/'+p.fileName,
            // thumbnail: axios.defaults.baseURL + "/api/photo/"+p.type+'/'+p.fileName,
            // thumbnailWidth: 80,
            // thumbnailHeight: 190,
            // isSelected: true,
            // caption: "After Rain (Jeshu John - designerspics.com)"
      }
    })

    let currentImageDetails = ''
    if(this.state.photos && this.state.photos.length){
      if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
        currentImageDetails = this.state.photos[this.state.currentImage].details
      }
    }
    let gallery = null
    if(galleryPhotos.length){
      gallery = (

            <Gallery key="_gallery"
              rowHeight={100}
              enableImageSelection={false} 
              backdropClosesModal={true}
              showLightboxThumbnails={true}
              images={galleryPhotos}
              currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
              customControls={[

                      <button key="deleteImage" onClick={()=>{
                        // console.log({deletePhoto:self.state})
                        self.deletePhoto((self.state.product.images || [])[self.state.currentImage])
                      }}>Delete</button>,

            // <textarea 
            //   className="form-control photo-description-textarea" 
            //   rows="7" 
            //   onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
            //   onBlur={()=>{self.savePhoto()}}
            //   value={currentImageDetails}
            //   placeholder="Photo Details..."/>
          ]}/>
      )
    }

    let queue = self.state.queue || []
    if(!queue || !Array.isArray(queue)){
      queue = []
    }
    let sales = self.state.sales || []
    if(!sales || !Array.isArray(sales)){
      sales = []
    }

    console.log({sp:this.state.product})
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{name}
	          </h1>

            <ol className="breadcrumb float-sm-right">
            {updateButton}

              <li key='li-space' id='sadas'>           
                &nbsp;&nbsp;&nbsp;
              </li>
            {activeButton}
            </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
            <form autoComplete="none">
            <input type="hidden" value="dummy" />

            	 
      			<div className="row">
        			<div className="col-6">

                      <label>SKU</label>
                      <input
                        disabled={true}
                        onChange={(e)=>{}}
                        value={(this.state.product || {id:''} ).id||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="sku"
                        placeholder="SKU"
                      />
                      

                      <label>Vendor SKU</label>
                      <input
                        onChange={(e)=>{
                          self.updateMetadataField('VENDORSKU',e.target.value)
                        }}
                        value={(this.state.product.metadata || {} ).VENDORSKU||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="vendorsku"
                        placeholder="Vendor SKU"
                      />

                      <label>Name</label>
                      <input
                        onChange={(e)=>{self.updateField('name',e.target.value)}}
                        value={this.state.product.name||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name"
                      />

                      <label>Type</label>
                      <select
                        onChange={(e)=>{
                          self.updateMetadataField('TYPE',e.target.value)
                        }}
                        value={(this.state.product.metadata || {} ).TYPE||''}
                        className="form-control"
                        id="catalog"
                      >
                        <option value="">Choose Type</option>
                        {Object.keys(ALL_TYPES).map((tt)=>{
                          return (
                            <option key={`ptype_${tt}`} value={tt}>{ALL_TYPES[tt]}</option>
                          )
                        })}
                      </select>

        			</div>
        			<div className="col-6">
                      <label>Price</label>
                      <input
                        onChange={(e)=>{self.updateSkuField('price',e.target.value)}}
                        value={((this.state.sku.price||0))}                        
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="number" pattern="\d*"
                        className="form-control"
                        id="price"
                        placeholder="Price (ex: 123.45)"
                      />

                      {/* <input
                        onChange={(e)=>{self.updateField('preOrderOnly',e.target.checked)}}
                        checked={this.state.product.preOrderOnly||''}                        
                        type="checkbox"
                        id="preOrderOnly"
                      />
                      <label htmlFor="preOrderOnly" style={{marginLeft:'5px'}}>Pre-Order</label><br/>

                      <div style={{display:(this.state.product.preOrderOnly?'block':'none')}}>
                        <label>Pre-Order Price</label>
                        <input
                          onChange={(e)=>{self.updateField('preOrderPrice',e.target.value)}}
                          value={this.state.product.preOrderPrice||''}                        
                          autoComplete="none"
                          autoCorrect="off"
                          autoCapitalize="off" 
                          type="text" pattern="\d*"
                          className="form-control"
                          id="preOrderPrice"
                          placeholder="Pre-Order Price (ex: 123.45)"
                        />
                      </div>

                      <input
                        onChange={(e)=>{self.updateField('inStock',e.target.checked)}}
                        checked={this.state.product.inStock||''}                        
                        type="checkbox"
                        id="inStock"
                      />
                      <label htmlFor="inStock" style={{marginLeft:'5px'}}>In-Stock</label><br/> */}

                      <div style={{display:(this.state.product.inStock?'block':'block')}}>
                        <label>Inventory</label>
                        <input
                          onChange={(e)=>{self.updateSkuField('inventory',{quantity: e.target.value, type: "finite", value: null})}}
                          value={((this.state.sku || {inventory:{quantity:0}}).inventory || {quantity:0}).quantity||0}                        
                          autoComplete="none"
                          autoCorrect="off"
                          autoCapitalize="off" 
                          type="number" pattern="\d*"
                          className="form-control"
                          id="inventory"
                          placeholder="Qty on Hand"
                        />
                      </div>

                      <label>Catalog</label>
                      {!this.state.isAddingCatalog && (
                        <>
                        <select
                          onChange={(e)=>{
                            if(e.target.value==='xx_add_xx'){
                              self.setState({isAddingCatalog:true,newCatalog:''})
                              window.setTimeout(()=>{
                                document.getElementById('newcatalog').focus()
                              },200)
                            }
                            else{
                              self.updateMetadataField('CATALOG',e.target.value)
                            }
                          }}
                          value={(this.state.product.metadata || {} ).CATALOG||''}
                          className="form-control"
                          id="catalog"
                        >
                          <option value="">Choose Catalog</option>
                          <option value="xx_add_xx">Add New</option>
                          {Object.keys(ALL_CATALOGS).map((cat)=>{
                            return (
                              <option key={`cat_${cat}`} value={cat}>{ALL_CATALOGS[cat]}</option>
                            )
                          })}
                          {(self.state.catalogs|| []).map((cc,cx)=>{
                            let ckey = cc.catalog.toLowerCase().replace(/[^a-z0-9]/gi,'')
                            return (
                              <option key={`cat_x${ckey}`} value={ckey}>{cc.catalog}</option>
                            )
                          })}
                        </select>
                        {((this.state.product.metadata || {} ).CATALOG||'').length && (
                            <a
                              href="#delete" 
                              style={{marginLeft:'10px'}}
                              onClick={(e)=>{
                                e.preventDefault()
                                let currentCatalogKey = ((this.state.product.metadata || {} ).CATALOG||'').toLowerCase().replace(/[^a-z0-9]/gi,'')
                                let dCatalog = self.state.catalogs.filter((cc)=>{
                                  let ckey = cc.catalog.toLowerCase().replace(/[^a-z0-9]/gi,'')
                                  return ckey === currentCatalogKey
                                })[0]
                                if(dCatalog && dCatalog._id){
                                  self.deleteCatalog(dCatalog._id,()=>{
                                    self.setState({isAddingCatalog:false,newCatalog:''},()=>{
                                      self.updateMetadataField('CATALOG','')
                                    })
                                  })
                                }
                              }}>delete</a>
                        ) || null}
                        </>
                      ) || null}
                      {this.state.isAddingCatalog && (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            id="newcatalog"
                            onChange={(e)=>{
                              self.setState({newCatalog:e.target.value.replace('  ',' ')})
                            }} />
                            <a
                              href="#add" 
                              style={{marginLeft:'10px'}}
                              onClick={(e)=>{
                                e.preventDefault()
                                const newCat = `${self.state.newCatalog}`
                                let newCatKey = newCat.toLowerCase().replace(/[^a-z0-9]/gi,'')
                                self.newCatalog(newCat)
                                self.setState({isAddingCatalog:false,newCatalog:''},()=>{                                  
                                  self.updateMetadataField('CATALOG',newCatKey)
                                })
                              }}>add</a>
                            <a
                              href="#cancel" 
                              style={{marginLeft:'10px'}}
                              onClick={(e)=>{
                                e.preventDefault()
                                self.setState({isAddingCatalog:false,newCatalog:''})
                              }}>cancel</a>
                        </>  
                      ) || null}
                        <div>
                        <input
                          type="checkbox"
                          checked={((self.state.product.metadata || {})['FEATURED'] || 'false') ==='true'}
                          onChange={(e)=>{
                            console.log({e})
                            self.updateMetadataField('FEATURED',(e.target.checked?'true':'false'))
                          }}
                          style={{maxWidth:'40px!important',marginRight:'10px'}}
                          id="pfeatured"
                        />
                        <label>Feature on home page</label><br/>
                        </div>

        			</div>
        		</div>

              <div className="col-12">&nbsp;</div>

                <div className="col-12">
                  <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
                         id="custom-content-description-tab" 
                         data-toggle="pill" 
                         href="#custom-content-description" 
                         role="tab" 
                         aria-controls="custom-content-description" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'description'})
                         }}>Description</a>
                    </li>
                    {/* <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='details'?' active':'')}
                         id="custom-content-details-tab" 
                         data-toggle="pill" 
                         href="#custom-content-details" 
                         role="tab" 
                         aria-controls="custom-content-details" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'details'})
                         }}>Details</a>
                    </li> */}
                    {/* <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='notes'?' active':'')}
                         id="custom-content-notes-tab" 
                         data-toggle="pill" 
                         href="#custom-content-notes" 
                         role="tab" 
                         aria-controls="custom-content-notes" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'notes'})
                         }}>Notes</a>
                    </li> */}
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='categories'?' active':'')}
                         id="custom-content-categories-tab" 
                         data-toggle="pill" 
                         href="#custom-content-categories" 
                         role="tab" 
                         aria-controls="custom-content-categories" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'categories'})
                         }}>Categories</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
                         id="custom-content-photos-tab" 
                         data-toggle="pill" 
                         href="#custom-content-photos" 
                         role="tab" 
                         aria-controls="custom-content-photos" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'photos'})
                         }}>Photos</a>
                    </li>
                    {/* <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='queue'?' active':'')}
                         id="custom-content-queue-tab" 
                         data-toggle="pill" 
                         href="#custom-content-queue" 
                         role="tab" 
                         aria-controls="custom-content-queue" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'queue'})
                         }}>Waitlist</a>
                    </li> */}
                    {/* <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='sales'?' active':'')}
                         id="custom-content-sales-tab" 
                         data-toggle="pill" 
                         href="#custom-content-sales" 
                         role="tab" 
                         aria-controls="custom-content-sales" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'sales'})
                         }}>Sales</a>
                    </li>  */}
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='options'?' active':'')}
                         id="custom-content-options-tab" 
                         data-toggle="pill" 
                         href="#custom-content-options" 
                         role="tab" 
                         aria-controls="custom-content-options" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'options'})
                         }}>Options</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='variations'?' active':'')}
                         id="custom-content-variations-tab" 
                         data-toggle="pill" 
                         href="#custom-content-variations" 
                         role="tab" 
                         aria-controls="custom-content-variations" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'variations'})
                         }}>Variations</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
                         id="custom-content-more-tab" 
                         data-toggle="pill" 
                         href="#custom-content-more" 
                         role="tab" 
                         aria-controls="custom-content-more" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'more'})
                         }}>More...</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="custom-content-below-tabContent">
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
                         id="custom-content-description" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-description-tab">
                           <div>
                            <input type="button" value="GPT" onClick={(e)=>{self.setState({showGPT:true})}} />
                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('description',e.target.value)}}
                              value={this.state.product.description}
                              placeholder="Description..."/>
                            </div>

                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='details'?' active':'')} 
                         id="custom-content-details" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-details-tab">
                       
                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('details',e.target.value)}}
                              value={this.state.product.details}
                              placeholder="Details..."/>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='notes'?' active':'')} 
                         id="custom-content-notes" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-notes-tab">
                       
                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('notes',e.target.value)}}
                              value={this.state.product.notes}
                              placeholder="Notes..."/>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='categories'?' active':'')} 
                         id="custom-content-notes" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-notes-tab">
                           
                          <input
                            type="text"
                            id="newcategory"
                            value={self.state.newCategory}
                            onChange={(e)=>{
                              self.setState({newCategory:`${e.target.value}`.replace('  ',' ').toLowerCase()})
                            }} />
                            <a
                              href="#add" 
                              style={{marginLeft:'10px'}}
                              onClick={(e)=>{
                                e.preventDefault()
                                const newCat = `${self.state.newCategory}`
                                self.newCategory(newCat)
                                
                                let categories = [...prodCategories].filter((cc)=>{return cc !== newCat})
                                categories.push(newCat)

                                self.setState({newCategory:''},()=>{            
                                  self.updateMetadataField('EJR-CAT',categories.join(','))
                                })
                              }}>add</a>
                       
                            {(self.state.categories|| []).map((cCat)=>{
                              let cc = cCat.category
                              return (
                                <div key={`dcb_${cc}`}>
                                  <input type="checkbox" id= {'cb_'+cc} onChange={(e)=>{
                                    
                                    let index = prodCategories.indexOf(cc)
                                    let categories = [...prodCategories]

                                    if(e.target.checked){
                                      if(index<0){
                                        categories.push(cc)
                                      }
                                    }
                                    else{
                                      if(index>=0){
                                        categories.splice(index,1)
                                      }
                                    }
                                    self.updateMetadataField('EJR-CAT',categories.join(','))
                                    // self.updateField('categories',categories)
                                    // console.log(e.target.value)
                                  }} checked={prodCategories.indexOf(cc)>=0?true:false} value={cc} />
                                  <label style={{marginLeft:'10px',fontWeight:'normal'}} htmlFor={'cb_'+cc}>{cc}</label>
                                  
                                  <a
                                    href="#delete" 
                                    style={{marginLeft:'10px'}}
                                    onClick={(e)=>{
                                      e.preventDefault()
                                      const currCat = self.state.categories.filter((ccx)=>{return cc !== ccx})[0]
                                      if(currCat){

                                        self.deleteCategory(currCat._id,()=>{

                                          let categories = [...prodCategories].filter((ccx)=>{return cc !== ccx})            
                                          self.updateMetadataField('EJR-CAT',categories.join(','))
                                        })
                                        
                                      }
                                      
                                    }}>delete</a>
                                </div>
                                )
                            })}
                            {Object.keys(ALL_CATEGORIES).map((cc)=>{
                              return (
                                <div key={`dcbx_${cc}`}>
                                  <input type="checkbox" id= {'cbx_'+cc} onChange={(e)=>{
                                    
                                    let index = prodCategories.indexOf(cc)
                                    let categories = [...prodCategories]

                                    if(e.target.checked){
                                      if(index<0){
                                        categories.push(cc)
                                      }
                                    }
                                    else{
                                      if(index>=0){
                                        categories.splice(index,1)
                                      }
                                    }
                                    self.updateMetadataField('EJR-CAT',categories.join(','))
                                    // self.updateField('categories',categories)
                                    // console.log(e.target.value)
                                  }} checked={prodCategories.indexOf(cc)>=0?true:false} value={cc} />
                                  <label style={{marginLeft:'10px',fontWeight:'normal'}} htmlFor={'cb_'+cc}>{cc}</label>
                                </div>
                                )
                            })}
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='options'?' active':'')} 
                         id="custom-content-notes" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-notes-tab">
                           
                          <div>
                          {/* <select
                            onChange={(e)=>{
                              self.updateMetadataField('EJR-VSIZE',e.target.value)
                            }}
                            value={(this.state.product.metadata || {} )['EJR-VSIZE']||'Size'}
                            // className="form-control"
                            id="vSize"
                          >
                            <option value="Size">Size</option>
                            <option value="Type">Type</option>
                          </select>  */}
                          <input 
                            type="text" 
                            placeholder="Size" 
                            style={{marginRight:'10px'}}
                            value={(this.state.product.metadata || {} )['EJR-VSIZE']}
                            onChange={(e)=>{
                              self.updateMetadataField('EJR-VSIZE',e.target.value.replace(/[^a-z0-9\s]/gi,''))
                            }} />
                          OPTIONS
                           <input type="button" style={{marginLeft:'10px'}} value="Clear" onClick={(e)=>{
                             self.updateMetadataField('EJR-SIZE-OPTS','')
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Birth Stones" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-SIZE-OPTS',[
                              ...prodSizeOptions,
                              'january-garnet',
                              'february-amethyst',
                              'march-aquamarine',
                              'april-diamond',
                              'may-emerald',
                              'june-alexandrite',
                              'july-ruby',
                              'august-peridot',
                              'september-sapphire',
                              'october-opal',
                              'november-citrine',
                              'december-topaz',
                              ].join(','))
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Metal Types" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-SIZE-OPTS',[
                              ...prodSizeOptions,
                              'gold',
                              'silver',
                              'platinum',
                              'whitegold',
                              'rosegold',
                              ].join(','))
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Ring Sizes" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-SIZE-OPTS',[
                              ...prodSizeOptions,
                              '4.5',
                              '5.0',
                              '5.5',
                              '6.0',
                              '6.5',
                              '7.0',
                              '7.5',
                              '8.0',
                              '8.5',
                              '9.0',
                              '9.5',
                              '10.0',
                              '10.5',
                              '11.0',
                              '11.5',
                              ].join(','))
                           }}/>
                           {prodSizeOptions.map((ps,psx)=>{
                              return (
                                <div key={`dpso_${ps}`}>
                                  <span>{ps}</span>
                                  <span 
                                    style={{marginLeft:'10px'}}>
                                    <a href="#delete" onClick={(e)=>{
                                      e.preventDefault()

                                      let sizeOptions = [...prodSizeOptions].filter((oo)=>{
                                        return oo !== ps
                                      })
                                      self.updateMetadataField('EJR-SIZE-OPTS',sizeOptions.join(','))
                                    }}>delete</a>
                                    </span>
                                </div>
                                )
                            })}
                            <div key={`dpso_add`}>
                              <input 
                                type="text"
                                style={{width:'220px'}}
                                value={self.state.sizeOption}
                                onChange={(e)=>{
                                  self.setState({sizeOption:e.target.value.replace(/[^0-9a-z\-\.]/gi,'').toLowerCase()})
                                }}
                              />
                              <span>
                                <a href="#add" 
                                  style={{marginLeft:'10px'}}
                                  onClick={(e)=>{
                                    e.preventDefault()

                                    let sizeOptions = [...prodSizeOptions].filter((oo)=>{
                                      return oo !== self.state.sizeOption
                                    })
                                    sizeOptions.push(self.state.sizeOption)
                                    
                                    self.setState({sizeOption:''},()=>{
                                      self.updateMetadataField('EJR-SIZE-OPTS',sizeOptions.join(','))
                                    })
                                  }}>add</a>
                                </span>
                            </div>
                          </div>
                          
                          <div>
                           <hr/>
                          {/* <select
                            onChange={(e)=>{
                              self.updateMetadataField('EJR-VCOLOR',e.target.value)
                            }}
                            value={(this.state.product.metadata || {} )['EJR-VCOLOR']||'Color'}
                            // className="form-control"
                            id="vColor"
                          >
                            <option value="Color">Color</option>
                            <option value="Material">Material</option>
                          </select> */}
                           <input 
                            type="text" 
                            placeholder="Color"  
                            style={{marginRight:'10px'}}
                            value={(this.state.product.metadata || {} )['EJR-VCOLOR']}
                            onChange={(e)=>{
                              self.updateMetadataField('EJR-VCOLOR',e.target.value.replace(/[^a-z0-9\s]/gi,''))
                            }} />
                           OPTIONS
                           <input type="button" style={{marginLeft:'10px'}} value="Clear" onClick={(e)=>{
                             self.updateMetadataField('EJR-COLOR-OPTS','')
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Birth Stones" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-COLOR-OPTS',[
                              ...prodColorOptions,
                              'january-garnet',
                              'february-amethyst',
                              'march-aquamarine',
                              'april-diamond',
                              'may-emerald',
                              'june-alexandrite',
                              'july-ruby',
                              'august-peridot',
                              'september-sapphire',
                              'october-opal',
                              'november-citrine',
                              'december-topaz',
                              ].join(','))
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Metal Types" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-COLOR-OPTS',[
                              ...prodColorOptions,
                              'gold',
                              'silver',
                              'platinum',
                              'whitegold',
                              'rosegold',
                              ].join(','))
                           }}/>
                           <input type="button" style={{marginLeft:'10px'}} value="Add Ring Sizes" onClick={(e)=>{
                             
                             self.updateMetadataField('EJR-COLOR-OPTS',[
                              ...prodColorOptions,
                              '4.5',
                              '5.0',
                              '5.5',
                              '6.0',
                              '6.5',
                              '7.0',
                              '7.5',
                              '8.0',
                              '8.5',
                              '9.0',
                              '9.5',
                              '10.0',
                              '10.5',
                              '11.0',
                              '11.5',
                              ].join(','))
                           }}/>


                           {prodColorOptions.map((pc,pcx)=>{
                              return (
                                <div key={`dpco_${pc}`}>
                                  <span>{pc}</span>
                                  <span 
                                    style={{marginLeft:'10px'}}>
                                    <a href="#delete" onClick={(e)=>{
                                      e.preventDefault()

                                      let colorOptions = [...prodColorOptions].filter((oo)=>{
                                        return oo !== pc
                                      })
                                      self.updateMetadataField('EJR-COLOR-OPTS',colorOptions.join(','))
                                    }}>delete</a>
                                    </span>
                                </div>
                                )
                            })}
                            <div key={`dpco_add`}>
                              <input 
                                type="text"
                                style={{width:'220px'}}
                                value={self.state.colorOption}
                                onChange={(e)=>{
                                  self.setState({colorOption:e.target.value.replace(/[^0-9a-z\-\.]/gi,'').toLowerCase()})
                                }}
                              />
                              <span>
                                <a href="#add" 
                                  style={{marginLeft:'10px'}}
                                  onClick={(e)=>{
                                    e.preventDefault()

                                    let colorOptions = [...prodColorOptions].filter((oo)=>{
                                      return oo !== self.state.colorOption
                                    })
                                    colorOptions.push(self.state.colorOption)
                                    
                                    self.setState({colorOption:''},()=>{
                                      self.updateMetadataField('EJR-COLOR-OPTS',colorOptions.join(','))
                                    })
                                  }}>add</a>
                                </span>
                            </div>
                          </div>

                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='variations'?' active':'')} 
                         id="custom-content-notes" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-notes-tab">
                           <p>
                             <input type="button" value="Clear" onClick={(e)=>{
                               self.updateMetadataField('EJR-VAR','')
                             }}/>
                             <input type="button" style={{marginLeft:'10px'}} value="Add all variations" onClick={(e)=>{
                               let opts = []
                               prodColorOptions.forEach((pc,pcx)=>{
                                prodSizeOptions.forEach((ps,psx)=>{
                                  opts.push(`${pc}|${ps}|1`)
                                })
                               })
                               self.updateMetadataField('EJR-VAR',opts.join(','))
                               //`${this.state.variationColorOption}|${this.state.variationSizeOption}|${this.state.variationStockOption}`

                             }}/>
                           </p>
                            <table>
                              <thead>
                                <tr>
                                  <th>{(this.state.product.metadata || {} )['EJR-VCOLOR']||'Color'}</th>
                                  <th>{(this.state.product.metadata || {} )['EJR-VSIZE']||'Size'}</th>
                                  <th>Inventory</th>
                                  {/* <th>Image</th> */}
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>                                    
                                    <select
                                      onChange={(e)=>{
                                        self.setState({variationColorOption:`${e.target.value}`.trim()})
                                      }}
                                      value={this.state.variationColorOption}
                                      // className="form-control"
                                      id="varCol">
                                      <option value="">Choose {(this.state.product.metadata || {} )['EJR-VCOLOR']||'Color'}</option>
                                        {prodColorOptions.map((pc,pcx)=>{
                                          return (
                                            <option key={`varCol${pc}`} value={pc}>{pc}</option>
                                            )
                                        })}
                                    </select>
                                  </td>
                                  <td>
                                    <select
                                      onChange={(e)=>{
                                        self.setState({variationSizeOption:`${e.target.value}`.trim()})
                                      }}
                                      value={this.state.variationSizeOption}
                                      // className="form-control"
                                      id="varSize">
                                        <option value="">Choose {(this.state.product.metadata || {} )['EJR-VSIZE']||'Size'}</option>
                                        {prodSizeOptions.map((ps,psx)=>{
                                          return (
                                            <option key={`varSz${ps}`} value={ps}>{ps}</option>
                                            )
                                        })}
                                    </select>
                                  </td>
                                  <td>
                                    <input 
                                      id="varInvOption"
                                      type="text"
                                      style={{width:'100px'}}
                                      value={self.state.variationStockOption}
                                      onChange={(e)=>{
                                        self.setState({variationStockOption:`${e.target.value}`.trim().replace(/[^0-9]/gi,'').toLowerCase()})
                                      }}
                                    />
                                  </td>
                                  {/* <td></td> */}
                                  <td style={{textAlign:'right'}}>
                                    {(`${this.state.variationColorOption}`.trim().length && `${this.state.variationSizeOption}`.trim().length && (

                                      <a href="#add" 
                                      onClick={(e)=>{
                                        e.preventDefault()

                                        let variationOptions = [...prodVariationOptions].filter((oo)=>{
                                          let sc = oo.split('|').slice(0,2).join('|')
                                          let test = `${this.state.variationColorOption}|${this.state.variationSizeOption}`
                                          return sc !== test
                                        })
                                        variationOptions.push(`${this.state.variationColorOption}|${this.state.variationSizeOption}|${this.state.variationStockOption}`)
                                        
                                        self.setState({variationColorOption:'',variationSizeOption:'',variationStockOption:'0'},()=>{
                                          self.updateMetadataField('EJR-VAR',variationOptions.join(','))
                                        })
                                      }}>add</a>
                                    )) || null}
                                  </td>
                                </tr>
                                
                                {prodVariationOptions.map((ps,psx)=>{
                                  let vParts = ps.split('|')
                                  return (
                                    <tr key={`dpvso_${ps}`}>
                                      <td>{vParts[0]}</td>
                                      <td>{vParts[1]}</td>
                                      <td>
                                        <input 
                                          id={`prodVarOpt${psx}`}
                                          type="text"
                                          value={vParts[2]}
                                          
                                          style={{width:'100px'}}
                                          onChange={(e)=>{
                                            let variationOptions = [...prodVariationOptions]
                                            if(variationOptions[psx]){
                                              let newOptions = variationOptions[psx].split('|').slice(0,2)
                                              newOptions.push(e.target.value.replace(/[^0-9]/gi,'')||'0')
                                              variationOptions[psx] = newOptions.join('|')
                                              self.updateMetadataField('EJR-VAR',variationOptions.join(','),()=>{
                                                console.log({focus:e.target})
                                                let fElem = `prodVarOpt${psx}`
                                                document.getElementById(fElem).focus()
                                              })
                                            }
                                          }}
                                          />
                                        </td>
                                      {/* <td></td> */}
                                      <td style={{textAlign:'right'}}>
                                        <a                                          
                                          href="#delete" 
                                          onClick={(e)=>{
                                            e.preventDefault()

                                            let variationOptions = [...prodVariationOptions].filter((oo)=>{
                                              let sc = oo.split('|').slice(0,2).join('|')
                                              let test = `${vParts[0]}|${vParts[1]}`
                                              return sc !== test
                                            })
                                            self.updateMetadataField('EJR-VAR',variationOptions.join(','))
                                            // let sizeOptions = [...prodSizeOptions].filter((oo)=>{
                                            //   return oo !== ps
                                            // })
                                            // self.updateMetadataField('EJR-SIZE-OPTS',sizeOptions.join(','))
                                          }}>delete</a>
                                      </td>
                                    </tr>
                                    )
                                })}
                              </tbody>
                            </table>
                          <div>

                          </div>
                          

                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='queue'?' active':'')} 
                         id="custom-content-queue" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-queue-tab">
                       

                        <table style={{minWidth:'480px'}}>
                          <thead>
                            <tr>
                              <th>Order Date</th>
                              <th>Customer</th>
                              <th>Transaction</th>
                            </tr>
                          </thead>
                          <tbody>
                            {queue.map((qq,qx)=>{
                              let customerName= [qq.customer.lastName,qq.customer.firstName].filter((cnp)=>{return cnp && cnp.trim().length}).join(', ')
                              return(

                                <tr key={'ql_'+qx}>
                                  <td>
                                    {(qq.created || '').split('T')[0]}
                                  </td>
                                  <td>
                                    <a href="#" onClick={(e)=>{
                                      e.preventDefault()
                                      localStorage.setItem("currentCustomer", qq.customer._id);
                                      window.location='/admin/customer/'
                                    }}>{customerName}</a>  
                                  </td>
                                  <td>
                                    <a href="#" onClick={(e)=>{
                                      e.preventDefault()
                                      localStorage.setItem("currentCustomer", qq.customer._id);
                                      window.location='/admin/transaction/'+qq.transaction._id
                                    }}>
                                      view transaction
                                    </a> 
                                  </td>
                                </tr>
                              )
                            })}
                            
                          </tbody>
                        </table>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='sales'?' active':'')} 
                         id="custom-content-sales" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-sales-tab">
                       

                        <table style={{minWidth:'480px'}}>
                          <thead>
                            <tr>
                              <th>Order Date</th>
                              <th>Customer</th>
                              <th>Transaction</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sales.map((qq,qx)=>{
                              let customerName= [qq.customer.lastName,qq.customer.firstName].filter((cnp)=>{return cnp && cnp.trim().length}).join(', ')
                              return(

                                <tr key={'ql_'+qx}>
                                  <td>
                                    {(qq.created || '').split('T')[0]}
                                  </td>
                                  <td>
                                    <a href="#" onClick={(e)=>{
                                      e.preventDefault()
                                      localStorage.setItem("currentCustomer", qq.customer._id);
                                      window.location='/admin/customer/'
                                    }}>{customerName}</a>  
                                  </td>
                                  <td>
                                    <a href="#" onClick={(e)=>{
                                      e.preventDefault()
                                      localStorage.setItem("currentCustomer", qq.customer._id);
                                      window.location='/admin/transaction/'+qq.transaction._id
                                    }}>
                                      view transaction
                                    </a> 
                                  </td>
                                </tr>
                              )
                            })}
                            
                          </tbody>
                        </table>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
                         id="custom-content-photos" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-photos-tab">
                       
                        <div className="col-2">
                  <PhotoEditor 
                  text="New Photo" 
                  onImageBlob={(data)=>{
                    self.newPhoto(data.blobFile)
                  }}/>
                </div>
                      {gallery}
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
                         id="custom-content-more" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-more-tab">
                       
                      <button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
                    </div>
                  </div>
                </div>

            </form>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
    {gptModal}
	</div>

      );
  }
}

export default Product;
